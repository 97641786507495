<template>
  <div
    :is="`h${level}`"
    class="base-title"
    :class="`base-title--${level}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-title {
  margin: 0 0 32px 0;
  font-weight: bold;
  font-family: $font-family-title;
  line-height: normal;
  letter-spacing: normal;

  &--1 {
    font-size: 28px;
    line-height: 26px;
  }

  &--2 {
    font-size: 22px;
  }

  &--3 {
    font-size: 16px;
  }
}
</style>

<template>
  <form-field
    :label="label"
    :name="name"
    :focussed="focussed"
    :loading="loading"
    :disabled="disabled"
    :valid="valid"
    :invalid="invalid"
    :errors="errors"
  >
    <label
      class="input"
      :class="classes"
    >
      <input
        ref="input"
        class="input-tag"
        :type="inputType"
        :value="value"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled || loading"
        autofocus
        @input="$emit('input', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      >
      <transition-fade>
        <base-icon
          v-if="passwordIcon"
          class="password-icon"
          :name="passwordIcon"
          @click.native="obscured = !obscured"
        />
        <base-icon
          v-else-if="statusIcon"
          class="status-icon"
          :name="statusIcon"
          :animation="loading ? 'spin' : null"
        />
      </transition-fade>
    </label>
  </form-field>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    slim: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focussed: false,
      obscured: true,
    };
  },
  computed: {
    inputType() {
      return this.type === 'password' && !this.obscured ? 'text' : this.type;
    },
    classes() {
      return {
        'input--slim': this.slim,
        'input--focussed': this.focussed,
        'input--loading': this.loading,
        'input--disabled': this.disabled,
        'input--valid': this.valid,
        'input--invalid': this.invalid,
        'input--full-width': this.fullWidth,
      };
    },
    statusIcon() {
      if (this.loading) {
        return 'loader-outline';
      } if (this.disabled) {
        return 'lock-outline';
      } if (this.invalid) {
        return 'alert-triangle-outline';
      } if (this.valid) {
        return 'checkmark-outline';
      }
      return null;
    },
    passwordIcon() {
      if (this.type === 'password' && this.obscured) {
        return 'eye-outline';
      } if (this.type === 'password' && !this.obscured) {
        return 'eye-off-outline';
      }
      return null;
    },
  },
  mounted() {
    if (this.autofocus) this.$refs.input.focus();
  },
  methods: {
    onFocus() {
      this.$emit('focus');
      this.focussed = true;
    },
    onBlur() {
      this.$emit('blur');
      this.focussed = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 280px;
  height: 48px;
  background: $color-white;
  border: solid 1px $color-gray70;
  border-radius: 4px;
  cursor: text;
  transition: border-color 150ms ease;

  &--valid {
    border-color: $color-success;

    .status-icon {
      color: $color-success;
    }
  }

  &--invalid {
    border-color: $color-danger;

    .status-icon {
      color: $color-danger;
    }
  }

  &--disabled,
  &--loading {
    background-color: $color-gray10;
    cursor: default;

    .input-tag,
    .status-icon {
      color: $color-gray70;
    }
  }

  &--focussed {
    border-color: $color-primary;
  }

  &--slim {
    height: 40px;
    background: transparent;
    border-top: none;
    border-right: none;
    border-bottom-width: 2px;
    border-left: none;
    border-radius: 0;

    .input-tag {
      padding: 0;
    }

    .status-icon {
      margin-right: 0;
    }
  }

  &--full-width {
    width: 100%;
  }
}

.input-tag {
  flex-grow: 1;
  width: 0;
  height: 100%;
  padding: 0 15px;
  font-size: 14px;
  font-family: $font-family-body;
  background: none;
  border: none;
  border-radius: 4px;
  outline: none;
  appearance: none;

  &::placeholder {
    color: $color-gray50;
  }

  /* Disable Chrome autocomplete styling */
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.password-icon {
  margin-right: 8px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $color-primary;
  }
}

.status-icon {
  margin-right: 8px;
  transition: color 150ms ease;
}
</style>

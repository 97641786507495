<template>
  <header
    class="main-header"
    :class="{
      'main-header--dark' : dark,
      'main-header--translucent' : translucent,
      'main-header--no-menu' : hideMenu,
    }"
  >
    <div
      class="background"
    />
    <div class="content">
      <div class="left" />

      <div class="logo" />

      <div class="right">
        <transition-fade>
          <base-spinner
            v-if="loading"
            class="spinner"
            :size="12"
            :type="spinnerType"
          />
        </transition-fade>
        <portal-target
          v-show="!hideMenu"
          class="menu"
          name="menu"
        >
          <base-icon
            class="hamburger"
            :name="menuOpen ? 'close-outline' : 'menu-outline'"
            :size="30"
            @click="$emit('toggle-menu')"
          />
        </portal-target>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    translucent: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    menuOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    spinnerType() {
      return this.dark ? 'light' : 'dark';
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  $root: &;

  box-sizing: border-box;
  padding-top: env(safe-area-inset-top, 24px);
  // backdrop-filter: opacity(20%) blur(2px);

  &--dark {
    .background {
      background-color: rgba($color-primary90, 0.9);
    }

    .logo {
      background-image: url('~@/assets/images/logo/light.svg');
    }
  }

  &--translucent {
    .background {
      opacity: 0;
      transition:
        background-color $page-transition-duration ease,
        opacity $page-transition-duration ease;
    }
  }

  &--no-menu {
    .content {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }

    .logo {
      margin-left: auto;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-gray10, 0.9);
  backdrop-filter: blur(3px);
  transition:
    background-color $page-transition-duration ease,
    opacity $page-transition-duration ease $page-transition-duration;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 720px;
  height: $header-height;
  margin: 0 auto;
  padding: 0 6vw;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  display: block;
  width: 80px;
  height: 17px;
  margin-right: auto;
  background: url('~@/assets/images/logo/dark.svg') no-repeat center center / 100% 100%;
}

.spinner {
  margin-right: 8px;
  opacity: 0.3;
}

.hamburger {
  cursor: pointer;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: background-color $page-transition-duration*2 ease;
// }

// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
// }
</style>

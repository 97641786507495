<template>
  <base-group
    class="form-radio-button-group"
    align="justify"
    vertical-align="top"
    spacing="small"
  >
    <slot />
  </base-group>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.form-radio-button-group {
  margin-bottom: 8px;
}
</style>

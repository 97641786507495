export default [
  {
    path: '/error503',
    name: 'error503',
    component: () => import(/* webpackChunkName: "error503" */ '@/views/Error503.vue'),
    meta: {
      allowGuests: true,
    },
  },
  {
    path: '/activation-failed',
    name: 'activation-failed',
    component: () => import(/* webpackChunkName: "error-activation" */ '@/views/ErrorActivation.vue'),
  },
];

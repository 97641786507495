export default [
  {
    path: '/responded',
    name: 'responded',
    component: () => import(/* webpackChunkName: "responded" */ '@/views/Responded.vue'),
    meta: {
      allowGuests: true,
      allowBrowser: true,
      darkMode: true,
      translucentHeader: true,
    },
    props: true,
  },
];

<template>
  <span
    class="base-badge"
    :class="'base-badge--' + type"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'default',
            'white',
            'primary',
            'secondary',
            'warning',
            'danger',
            'success',
          ].indexOf(value) !== -1
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  color: $color-text;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  background-color: $color-gray10;
  border-radius: 4px;

  &--white {
    background-color: $color-white;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--danger {
    color: $color-white;
    background-color: $color-danger;
  }

  &--warning {
    color: $color-white;
    background-color: $color-warning;
  }

  &--success {
    color: $color-white;
    background-color: $color-success;
  }
}
</style>

import {
  Plugins,
  Capacitor,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

export default {
  register({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);

      // Web push not yet supported
      if (Capacitor.platform === 'web') {
        reject(new Error('Web push unsupported'));
      }

      // Request push permission
      PushNotifications.requestPermission().then((permission) => {
        console.log('Permission Requested', permission);
        if (permission.granted) {
          commit('SET_GRANTED', true);
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          resolve(new Error('Permission granted'));
        } else {
          // No permission for push granted
          commit('SET_GRANTED', false);
          reject(new Error('Permission not granted'));
        }
      }).finally(() => {
        commit('SET_LOADING', false);
      });
    });
  },

};

<template>
  <base-group
    class="form-group"
    :align="align"
    vertical-align="top"
    spacing="medium"
  >
    <slot />
  </base-group>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: 'left',
    },
  },
};
</script>

<template>
  <span
    class="base-icon"
    :class="classes"
    :style="styles"
    @click="$emit('click')"
  >
    <!-- eslint-disable vue/no-v-html -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :fill="color"
      v-html="icon"
    />
  </span>
</template>

<script>
import * as eva from 'eva-icons/eva';

export default {
  props: {
    name: {
      type: String,
      default: undefined,
      validator(value) {
        return eva.icons[value];
      },
    },
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    flipped: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: String,
      default: null,
      validator(value) {
        // The value must match one of these strings
        return ['spin'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    icon() {
      return eva.icons[this.name].contents;
    },
    styles() {
      if (Number(this.size)) {
        return { width: `${this.size}px`, height: `${this.size}px` };
      }
      return { width: this.size, height: this.size };
    },
    classes() {
      return [
        this.animation ? `base-icon--animate-${this.animation}` : '',
        { 'base-icon--flipped': this.flipped },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-icon {
  display: inline-block;
  vertical-align: middle;

  &--animate-spin {
    svg {
      animation: spin 1500ms linear infinite;
    }
  }

  &--flipped {
    transform: scaleX(-1);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

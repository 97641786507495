import {
  Plugins,
  StatusBarStyle,
} from '@capacitor/core';

const StatusBarPlugin = Plugins.StatusBar;

export default class StatusBar {
  static light() {
    StatusBarPlugin.setStyle({
      style: StatusBarStyle.Light,
    }).catch(() => {});
  }

  static dark() {
    StatusBarPlugin.setStyle({
      style: StatusBarStyle.Dark,
    }).catch(() => {});
  }

  static transparant() {
    // Display content under transparent status bar (Android only)
    StatusBarPlugin.setOverlaysWebView({
      overlay: true,
    }).catch(() => {});
  }

  static opaque() {
    StatusBarPlugin.setOverlaysWebView({
      overlay: false,
    }).catch(() => {});
  }

  static hide() {
    this.hide();
  }

  static show() {
    this.show();
  }
}

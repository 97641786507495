import { get, set } from '@/utils/storage';

export default {
  async setToken({ commit }, token) {
    // Persist token in Capacitor Storage
    await set('token', token);
    // Set token
    commit('SET_TOKEN', token);
  },

  async fetchToken({ commit }) {
    const token = await get('token');
    commit('SET_TOKEN', token);
  },

  fetchAuthenticated({ commit }) {
    return new Promise((resolve, reject) => {
      // Set loading state
      commit('SET_LOADING', true);
      // Login
      this.$axios
        .post('/api/authenticated')
        .then((response) => {
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          // Clear loading state
          commit('SET_LOADING', false);
        });
    });
  },

  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      // Set loading state
      commit('SET_LOADING', true);
      // Login
      this.$axios
        .post('/api/logout')
        .then((response) => {
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          // Clear token
          dispatch('setToken', null);
          // Clear loading state
          commit('SET_LOADING', false);
        });
    });
  },

  requestLoginLink({ commit }, { email }) {
    return new Promise((resolve, reject) => {
      // Set loading state
      commit('SET_LOADING', true);
      // Login
      this.$axios
        .post('/api/request-login', {
          email,
        })
        .then((response) => {
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Post failed
          // Reject promise
          reject(error);
        })
        .finally(() => {
          // Clear loading state
          commit('SET_LOADING', false);
        });
    });
  },
};

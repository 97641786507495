export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      allowGuests: true,
      hideMainMenu: true,
    },
  },
  {
    path: '/login-request',
    name: 'login-request',
    component: () => import(/* webpackChunkName: "login-request" */ '@/views/LoginRequest.vue'),
    meta: {
      allowGuests: true,
      hideMainMenu: true,
    },
  },
  {
    path: '/login-requested',
    name: 'login-requested',
    component: () => import(/* webpackChunkName: "login-requested" */ '@/views/LoginRequested.vue'),
    meta: {
      allowGuests: true,
      hideMainMenu: true,
    },
  },
];

<template>
  <div
    class="base-group"
    :class="classes"
    :style="styles"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: 'left',
      validator(value) {
        // The value must match one of these strings
        return ['left', 'center', 'right', 'justify'].indexOf(value) !== -1;
      },
    },
    verticalAlign: {
      type: String,
      default: 'middle',
      validator(value) {
        // The value must match one of these strings
        return ['top', 'middle', 'bottom'].indexOf(value) !== -1;
      },
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator(value) {
        // The value must match one of these strings
        return ['horizontal', 'vertical'].indexOf(value) !== -1;
      },
    },
    width: {
      type: String,
      default: 'none',
    },
    spacing: {
      type: String,
      default: 'auto',
      validator(value) {
        // The value must match one of these strings
        return ['auto', 'large', 'medium', 'small', 'tiny'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    spacingSize() {
      switch (this.spacing) {
        case 'auto':
          return '0.5em';
        case 'large':
          return '48px';
        case 'medium':
          return '32px';
        case 'small':
          return '16px';
        case 'tiny':
          return '8px';
        default:
          return '0';
      }
    },
    classes() {
      return [
        `base-group--${this.align}`,
        `base-group--${this.verticalAlign}`,
        `base-group--${this.direction}`,
      ];
    },
    styles() {
      return {
        '--spacing': this.spacingSize,
        'max-width': this.width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-group {
  $root: &;

  display: flex;
  flex-wrap: wrap;

  &--horizontal {
    align-items: center;

    &#{$root}--left {
      > * {
        margin-right: var(--spacing);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &#{$root}--center {
      justify-content: center;
      margin-right: calc(var(--spacing) * -0.5);
      margin-left: calc(var(--spacing) * -0.5);

      > * {
        margin-right: calc(var(--spacing) * 0.5);
        margin-left: calc(var(--spacing) * 0.5);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &#{$root}--right {
      justify-content: flex-end;

      & > * {
        margin-left: var(--spacing);

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &#{$root}--justify {
      margin-right: calc(var(--spacing) * -0.5);
      margin-left: calc(var(--spacing) * -0.5);

      & > * {
        flex-basis: 0;
        flex-grow: 1;
        margin-right: calc(var(--spacing) * 0.5);
        margin-left: calc(var(--spacing) * 0.5);
      }
    }

    &#{$root}--top {
      align-items: flex-start;
    }

    &#{$root}--bottom {
      align-items: flex-end;
    }
  }

  &--vertical {
    flex-direction: column;

    & > * {
      margin-top: calc(var(--spacing) * 0.5);
      margin-bottom: calc(var(--spacing) * 0.5);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &#{$root}--bottom {
      justify-content: flex-end;
    }
  }
}
</style>

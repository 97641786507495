export default {
  fetchIntake({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .get('api/intake', { params: { intake_key: getters.getKey } })
        .then((response) => {
          const categories = response.data.data;
          // Log in user
          commit('SET_CATEGORIES', categories);
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  submitIntake({
    commit, dispatch, getters, rootGetters,
  }, answers) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .post('/api/intake', {
          intake_key: getters.getKey,
          answers,
        }).then((response) => {
          const authenticated = rootGetters['auth/isAuthenticated'];
          if (authenticated) {
            // User is logged in, we should update user data
            dispatch('user/fetchUser', null, { root: true });
          }
          // Resolve promise
          resolve(response);
        }).catch((error) => {
          // Reject promise
          reject(error);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
};

<template>
  <p class="base-paragraph">
    <slot />
  </p>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.base-paragraph {
  margin: 0 0 32px 0;
  line-height: 1.8em;
}
</style>

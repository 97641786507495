export default {
  fetchCompany({ commit }, { intakeKey, pulseKey } = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .get('api/company', {
          params: {
            intake_key: intakeKey,
            pulse_key: pulseKey,
          },
        })
        .then((response) => {
          const company = response.data.data;
          // Log in user
          commit('SET_COMPANY', company);
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
};

<template>
  <div
    class="base-spinner"
    :class="'base-spinner--' + type"
  >
    <div
      class="content"
      :style="style"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'dark',
      validator(value) {
        // The value must match one of these strings
        return (['dark', 'light'].indexOf(value) !== -1
        );
      },
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    style() {
      return {
        'border-size': `${this.size / 4}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .base-spinner {
    &--light {
      .content {
        border-color: rgba($color-white, 0.5);
        border-bottom-color: rgba($color-white, 0.1);
      }
    }

    &--dark {
      .content {
        border-color: rgba($color-text, 0.5);
        border-bottom-color: rgba($color-text, 0.1);
      }
    }
  }

  .content {
    border-style: solid;
    border-radius: 100%;
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

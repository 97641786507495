export default {
  fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .get('api/user')
        .then((response) => {
          const user = response.data.data;
          // Log in user
          commit('SET_USER', user);
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  createNotificationToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .post('/api/user/notification-token', {
          token,
        }).then((response) => {
          // Resolve promise
          resolve(response);
        }).catch((error) => {
          // Reject promise
          reject(error);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
};

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity $page-transition-duration/2 ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

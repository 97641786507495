<template>
  <div
    class="base-widget"
    :class="classes"
  >
    <div class="content">
      <slot />
    </div>
    <div class="button">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'light',
      validator(value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    classes() {
      return [
        `base-widget--${this.type}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-widget {
  flex-grow: 1;
  overflow: hidden;
  border-radius: 16px;

  &--light {
    color: $color-text;
    background-color: $color-white;
    box-shadow: 0 8px 16px rgba($color-primary90, 0.075);
  }

  &--dark {
    color: $color-white;
    background-color: $color-primary90;
    box-shadow: 0 8px 16px rgba($color-primary90, 0.15);
  }
}

.content {
  padding: 24px;
}
</style>

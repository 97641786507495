<template>
  <div
    class="base-drawer"
  >
    <div
      v-show="isOpen"
      class="background"
      @click="$emit('close')"
    />
    <transition name="slide">
      <div
        v-show="isOpen"
        class="sidebar"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: !!this.open,
    };
  },
  watch: {
    open(open) {
      this.isOpen = !!open;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-drawer {
  z-index: 1;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 300px;
  max-width: calc(100% - 80px);
  overflow-y: auto;
  background-color: $color-primary90;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
}

.slide-leave-active {
  transition: transform 300ms ease;
}

.slide-enter-active {
  transition: transform 300ms ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
</style>

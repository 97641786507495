<template>
  <ul
    v-if="errors && errors.length"
    class="form-errors"
  >
    <li
      v-for="error in errors"
      :key="error"
      class="error"
    >
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.form-errors {
  padding: 0;
  color: $color-danger;
  font-size: 12px;
  list-style-type: none;
}

.error {
  &::before {
    content: '•';
  }
  // padding: 0;
}
</style>

export default {
  isLoading(state) {
    return state.loading;
  },

  isGranted(state) {
    return state.granted;
  },

  isRegistered(state) {
    return state.registered;
  },

  getToken(state) {
    return state.token;
  },
};

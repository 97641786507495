export default {
  isLoading(state) {
    return state.loading;
  },

  isAuthenticated(state) {
    return !!state.token;
  },

  getToken(state) {
    return state.token;
  },
};

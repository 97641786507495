import Vue from 'vue';
import axios from 'axios';

import router from '@/router';
import store from '@/store';

// Initialize axios
Vue.prototype.$axios = axios;
store.$axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

// Response interceptor for API calls
axios.interceptors.response.use((response) => response, async (error) => {
  // Check if the current route requires authentication
  const authRequired = !router.history.current.meta.allowGuests;
  // Check if we want to handle this error code
  let redirectTo = '';
  switch (error.response.status) {
    case 401: // Unauthorized
      // Ignore unauthorized errors for guest pages
      if (authRequired) {
        // Clear token
        store.dispatch('auth/setToken', null);
        // Redirect to login page
        redirectTo = 'login-request';
      }
      break;
    case 503: // Service Unavailable
      redirectTo = 'error503'; break;
    default: redirectTo = '';
  }
  // Redirect if necessary
  if (redirectTo) {
    router.push({ name: redirectTo });
  }
  // Reject promise
  return Promise.reject(error);
});

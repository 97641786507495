<template>
  <span
    class="base-skeleton-box"
    :class="'base-skeleton-box--' + type"
    :style="{ height, width: computedWidth }"
  />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: 100,
      type: Number,
    },
    minWidth: {
      default: 50,
      type: Number,
    },
    height: {
      default: '1.2em',
      type: String,
    },
    width: {
      default: null,
      type: String,
    },
    type: {
      type: String,
      default: 'medium',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'medium',
            'light',
            'dark',
          ].indexOf(value) !== -1
        );
      },
    },
  },
  computed: {
    computedWidth() {
      // Either use the given fixed width,
      // or a random width between the given min and max values.
      return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-skeleton-box {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    content: '';
    // animation: shimmer 5s infinite;
    // background-image:
    //   linear-gradient(
    //     90deg,
    //     rgba(#fff, 0) 0,
    //     rgba(#fff, 0.05) 20%,
    //     rgba(#fff, 0.2) 60%,
    //     rgba(#fff, 0)
    //   );
  }

  &--light {
    background-color: rgba($color-gray10, 0.7);
  }

  &--medium {
    background-color: rgba($color-gray50, 0.5);
  }

  &--dark {
    background-color: rgba($color-gray90, 0.3);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
 </style>

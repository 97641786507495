export default {
  isLoading(state) {
    return state.loading;
  },

  getKey(state) {
    return state.key;
  },

  getCategories(state) {
    return state.categories;
  },
};

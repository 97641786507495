import store from '@/store';
import { openRouteFromUrl } from '@/utils/helpers';
import { Plugins, Capacitor } from '@capacitor/core';

const { PushNotifications } = Plugins;

export default class PushService {
  static init() {
    if (Capacitor.platform === 'web') return;

    console.log('initializing');

    // // Check current permissions @TODO Capacitor@V3
    // PushNotifications.checkPermission().then((permission) => {
    //   console.log(permission);
    // });

    // Listen for push registration event
    PushNotifications.addListener('registration', (token) => {
      console.log(`My token: ${JSON.stringify(token)}`);
      store.commit('push/SET_TOKEN', token.value);
      store.commit('push/SET_REGISTERED', true);
      store.dispatch('user/createNotificationToken', token.value);
    });

    // Listen for push registration error events
    PushNotifications.addListener('registrationError', (error) => {
      console.log(`Error: ${JSON.stringify(error)}`);
      store.commit('push/SET_TOKEN', null);
      store.commit('push/SET_REGISTERED', false);
    });

    // Listen for received push notification events
    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      console.log(`Push received: ${JSON.stringify(notification)}`);
    });

    // Listen for receiver push notification action events
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      console.log(`Action performed: ${JSON.stringify(notification.notification)}`);
      // Strip protocol and domain name from url
      openRouteFromUrl(notification.notification.data.url);
    });
  }
}

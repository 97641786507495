<template>
  <label
    class="form-radio-button"
    :class="classes"
    :disabled="disabled"
  >
    <input
      class="radio"
      type="radio"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('input', value)"
    >
    <base-icon
      v-if="loading"
      class="icon icon--loading"
      name="loader-outline"
      :size="16"
      animation="spin"
    />
    <span
      v-else
      class="label"
    >
      <slot>{{ switchLabel }}</slot>
    </span>
    <span
      class="checkmark"
    >
      <base-icon
        name="checkmark-outline"
        size="16"
      />
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    switchLabel: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        // The value must match one of these strings
        return ['large', 'medium', 'small', 'tiny'].indexOf(value) !== -1;
      },
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      focussed: false,
    };
  },
  computed: {
    classes() {
      return [
        `form-radio-button--${this.size}`,
        { 'form-radio-button--dark-mode': this.darkMode },
        { 'form-radio-button--checked': this.checked },
        { 'form-radio-button--focussed': this.focussed },
        { 'form-radio-button--loading': this.loading },
        { 'form-radio-button--disabled': this.disabled },
        { 'form-radio-button--valid': this.valid },
        { 'form-radio-button--invalid': this.invalid },
      ];
    },
    checked() {
      return this.value === this.modelValue;
    },
    hasLabel() {
      return !!this.switchLabel || !!this.$slots.default;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.form-radio-button {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 8px;
  border: solid 1px $color-gray90;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 200ms ease, background-color 200ms ease;
  user-select: none;

  &--tiny {
    height: 32px;
  }

  &--small {
    height: 48px;
  }

  &--medium {
    height: 96px;
  }

  &--large {
    height: 128px;
  }

  &--dark-mode {
    border-color: $color-primary10;

    .label {
      color: $color-primary10;
    }

    &#{$root}--checked {
      background-color: $color-white;
      border-color: $color-white;

      .label {
        color: $color-white;
        color: $color-primary;
      }
    }

    &#{$root}--disabled,
    &#{$root}--loading {
      border-color: $color-primary70;

      &#{$root}--checked {
        background-color: $color-primary10;
      }

      .label {
        color: $color-primary70;
      }
    }
  }

  &--checked {
    border-color: $color-primary;

    .label {
      color: $color-primary;
    }

    .checkmark {
      opacity: 1;
    }
  }

  &--disabled,
  &--loading {
    border-color: $color-gray50;
    cursor: auto;
    pointer-events: none;

    .label {
      color: $color-gray50;
    }
  }
}

.radio {
  display: none;
}

.label {
  color: $color-text;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  transition: color 200ms ease;
}

.checkmark {
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $color-white;
  background-color: $color-primary;
  border-radius: 24px;
  opacity: 0;
  transition: opacity 200ms ease;
}
</style>

export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_GRANTED(state, payload) {
    state.granted = payload;
  },

  SET_REGISTERED(state, payload) {
    state.registered = payload;
  },

  SET_TOKEN(state, payload) {
    state.token = payload;
  },
};

export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_KEY(state, payload) {
    state.key = payload;
  },

  SET_PULSE(state, payload) {
    state.pulse = payload;
  },
};

export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_KEY(state, payload) {
    state.key = payload;
  },

  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
};

export default {
  fetchPulse({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .get('api/pulse', { params: { pulse_key: getters.getKey } })
        .then((response) => {
          const pulse = response.data.data;
          // Log in user
          commit('SET_PULSE', pulse);
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  submitPulse({
    commit, dispatch, getters, rootGetters,
  }, answer) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .post('/api/pulse', {
          pulse_key: getters.getKey,
          answer,
        }).then((response) => {
          const authenticated = rootGetters['auth/isAuthenticated'];
          if (authenticated) {
            // User is logged in, we should update user data
            dispatch('user/fetchUser', null, { root: true });
          }
          // Resolve promise
          resolve(response);
        }).catch((error) => {
          // Reject promise
          reject(error);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
};

export default [
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '@/views/Download.vue'),
    meta: {
      allowGuests: true,
      allowBrowser: true,
      darkMode: true,
    },
  },

];

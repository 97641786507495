<template>
  <section
    class="page"
    :class="{ 'page--centered': centered, 'page--fullscreen': fullscreen }"
  >
    <portal to="menu">
      <slot name="menu" />
    </portal>

    <transition-fade>
      <base-spinner
        v-if="loading"
        class="spinner"
        :type="spinnerType"
      />
      <div
        v-else
        class="content"
      >
        <slot />
      </div>
    </transition-fade>
  </section>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'light',
      validator(value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
    centered: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    spinnerType() {
      return this.type === 'dark' ? 'light' : 'dark';
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 3vh 6vw;

  &--centered {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
  }

  &--fullscreen {
    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center; /* Remove to disable centering content */
    }
  }
}

.content {
  width: 100%;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

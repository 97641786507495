/* eslint-disable import/prefer-default-export */

import router from '@/router';

/**
 * Navigate to a route extracted from the given url
 * @param {string} url A full URL
 */
export function openRouteFromUrl(url) {
  // Strip protocol and domain name from url
  const element = document.createElement('a');
  element.href = url;
  const path = element.pathname + element.search + element.hash;
  // Push to the route if there is a path present
  if (path) {
    router.push({
      path,
    });
  }
}

export default {
  fetchInactivePulses({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      this.$axios
        .get('api/user/inactive-pulses')
        .then((response) => {
          const pulses = response.data.data;
          // Log in user
          commit('SET_INACTIVE_PULSES', pulses);
          // Resolve promise
          resolve(response);
        })
        .catch((error) => {
          // Reject promise
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import { Capacitor } from '@capacitor/core';

import Error404 from '@/views/Error404.vue';

import about from './about';
import download from './download';
import errors from './errors';
import feedback from './feedback';
import home from './home';
import intake from './intake';
import login from './login';
import onboarding from './onboarding';
import preferences from './preferences';
import privacy from './privacy';
import proclaimer from './proclaimer';
import pulse from './pulse';
import responded from './responded';

Vue.use(VueRouter);

// Define routes
const routes = [
  ...about,
  ...download,
  ...errors,
  ...feedback,
  ...home,
  ...intake,
  ...login,
  ...onboarding,
  ...preferences,
  ...privacy,
  ...proclaimer,
  ...pulse,
  ...responded,
  { path: '*', component: Error404 },
];
// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    name: 'error404',
    component: Error404,
    meta: {
      allowGuests: true,
    },
  });
}

// Create new router instance
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

const fetchToken = store.dispatch('auth/fetchToken');

router.beforeEach((to, from, next) => {
  // Check if this page can only be displayed in the app
  const appRequired = to.matched.some((record) => !record.meta.allowBrowser);
  const isApp = Capacitor.isNative;
  if (appRequired && !isApp && process.env.NODE_ENV !== 'development') {
    next({ name: 'download' });
  }
  // Make sure the authentication token is fetched from local storage
  fetchToken.then(() => {
    // Check if this route requires authentication and the user is logged In
    const authRequired = to.matched.some((record) => !record.meta.allowGuests);
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    if (authRequired && !isAuthenticated) {
      // Authorization is required for this route but the user is not logged in.
      next({
        name: 'login-request',
        query: { redirect: to.name },
      });
    }
    // Continue to the next route
    next();
  });
});

export default router;

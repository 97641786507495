<template>
  <div
    class="base-graphic"
    :class="{ 'base-graphic--flipped': flipped } "
  >
    <div
      v-if="!flipped"
      class="image"
    >
      <img :src="image">
    </div>

    <div class="text">
      <base-title
        class="title"
        :level="1"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </base-title>

      <div class="body">
        <slot name="body">
          {{ body }}
        </slot>
      </div>

      <div class="footer">
        <slot name="footer">
          {{ footer }}
        </slot>
      </div>
    </div>

    <div
      v-if="flipped"
      class="image"
    >
      <img :src="image">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    footer: {
      type: String,
      default: '',
    },
    flipped: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-graphic {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  text-align: center;

  &--flipped {
    .image {
      margin-bottom: 0;
    }

    .text {
      margin-bottom: 5vh;
    }
  }
}

.image {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  min-height: 0;
  margin-bottom: 5vh;
  text-align: center;

  img {
    width: 360px;
    max-width: 100%;
    height: 100%;
  }
}

.title {
  margin-bottom: 16px;
}

.body {
  flex-grow: 1;
  justify-content: center; /* Remove to disable centering content */
}

.footer {
  width: 100%;
  margin: 0 auto;
}
</style>

<template>
  <div
    class="form-field"
    :class="classes"
  >
    <label
      v-if="label"
      class="label"
      :for="name"
    >{{ label }}</label>
    <slot />
    <form-errors :errors="errors" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    focussed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    classes() {
      return {
        'form-field--focussed': this.focussed,
        'form-field--loading': this.loading,
        'form-field--disabled': this.disabled,
        'form-field--valid': this.valid,
        'form-field--invalid': this.invalid,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  margin-bottom: 24px;
  transition: color 150ms ease;

  &--valid {
    color: $color-success;
  }

  &--invalid {
    color: $color-danger;
  }

  &--disabled {
    color: $color-gray70;
  }

  &--focussed {
    color: $color-primary;
  }
}

.label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 13px;
}
</style>

<template>
  <transition
    name="fade-zoom"
    mode="out-in"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'in',
      validator(value) {
        // The value must match one of these strings
        return ['in', 'out'].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    beforeEnter(el) {
      const element = el;
      element.style.opacity = 0;
      element.style.transform = this.direction === 'in' ? 'scale(0.9)' : 'scale(1.1)';
    },
    enter(el) {
      const element = el;
      window.setTimeout(() => {
        element.style.opacity = 1;
        element.style.transform = 'scale(1.0)';
      }, 1);
    },
    leave(el) {
      const element = el;
      element.style.opacity = 0;
      element.style.transform = this.direction === 'in' ? 'scale(1.1)' : 'scale(0.9)';
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-zoom-leave-active,
.fade-zoom-enter-active {
  transition: all 300ms ease;
}
</style>

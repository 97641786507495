export default {
  isLoading(state) {
    return state.loading;
  },

  getKey(state) {
    return state.key;
  },

  getPulse(state) {
    return state.pulse;
  },
};

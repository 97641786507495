import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import user from './user';
import company from './company';
import activePulses from './activePulses';
import inactivePulses from './inactivePulses';
import intake from './intake';
import pulse from './pulse';
import push from './push';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    company,
    activePulses,
    inactivePulses,
    intake,
    pulse,
    push,
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  // strict: process.env.DEBUGGING, // @TODO
});

export default [
  {
    path: '/intake',
    name: 'intake',
    component: () => import(/* webpackChunkName: "intake" */ '@/views/Intake.vue'),
    meta: {
      allowGuests: true,
      allowBrowser: true,
      darkMode: true,
    },
  },
];

import axios from 'axios';

export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_TOKEN(state, payload) {
    // Use token as Bearer token in axios
    const header = payload ? `Bearer ${payload}` : '';
    axios.defaults.headers.common.Authorization = header;
    // Save token
    state.token = payload;
  },

  SET_USER(state, payload) {
    state.user = payload;
  },
};

<template>
  <aside class="main-menu">
    <div class="logo" />

    <ul class="list">
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'home' }"
          @click.native="onClickLink()"
        >
          Home
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'preferences' }"
          @click.native="onClickLink()"
        >
          Voorkeuren
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'about' }"
          @click.native="onClickLink()"
        >
          Over Volved
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'privacy' }"
          @click.native="onClickLink()"
        >
          Privacy
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ path: '/proclaimer' }"
          @click.native="onClickLink()"
        >
          Proclaimer
        </router-link>
      </li>
      <li class="list-item">
        <a
          href="#"
          class="list-link"
          @click="logout()"
        >
          Logout
        </a>
      </li>
    </ul>
    <ul class="list">
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'onboarding' }"
          @click.native="onClickLink()"
        >
          Onboarding
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'pulse' }"
          @click.native="onClickLink()"
        >
          Pulse
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          class="list-link"
          :to="{ name: 'responded' }"
          @click.native="onClickLink()"
        >
          Responded
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  methods: {
    onClickLink() {
      this.$emit('select');
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        // Valid token, move to onboarding route
        this.$router.push({ name: 'login-request' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  padding: 20px;
  padding-top: calc(20px + constant(safe-area-inset-top));
  padding-top: calc(20px + env(safe-area-inset-top));
}

.logo {
  display: block;
  width: 80px;
  height: 17px;
  margin-right: auto;
  background: url('~@/assets/images/logo/light.svg') no-repeat center center / 100% 100%;
}

.list {
  margin-top: 30px;
  padding: 0;
  color: #fff;
  list-style-type: none;
}

.list-item {
  padding: 8px 0;
  // color: #fff;
}

.list-link {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

@supports (padding-top: constant(safe-area-inset-top)) {
  .main-menu {
    padding-top: calc(20px + constant(safe-area-inset-top));
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .main-menu {
    padding-top: calc(20px + env(safe-area-inset-top));
  }
}
</style>

<template>
  <div
    id="q-app"
    ref="app"
    class="app"
    :class="{
      'app--dark': darkMode,
    }"
  >
    <main
      ref="main"
      class="main"
    >
      <main-header
        class="header"
        :dark="darkMode"
        :translucent="translucentHeader"
        :hide-menu="hideMainMenu || !isAuthenticated"
        :menu-open="isMenuOpen && isAuthenticated"
        :loading="isLoading"
        @toggle-menu="isMenuOpen = !isMenuOpen"
      />
      <transition-fade>
        <router-view
          class="body"
          @refresh="fetchData"
        />
      </transition-fade>
    </main>
    <base-drawer
      class="menu"
      :open="isMenuOpen && isAuthenticated"
      @close="isMenuOpen = false"
    >
      <main-menu @select="isMenuOpen = false" />
    </base-drawer>
  </div>
</template>

<script>
import MainMenu from '@/components/layout/MainMenu.vue';
import MainHeader from '@/components/layout/MainHeader.vue';

import StatusBarService from '@/utils/StatusBarService';
import PushService from '@/utils/PushService';

import { set } from '@/utils/storage';
import { openRouteFromUrl } from '@/utils/helpers';

import { Capacitor, App } from '@capacitor/core';

export default {
  components: {
    MainMenu,
    MainHeader,
  },
  data() {
    return {
      hideMainMenu: false,
      darkMode: false,
      translucentHeader: false,
      isMenuOpen: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    company() {
      return this.$store.getters['company/getCompany'];
    },
    isLoading() {
      return this.$store.getters['user/isLoading']
        || this.$store.getters['company/isLoading']
        || this.$store.getters['activePulses/isLoading']
        || this.$store.getters['inactivePulses/isLoading'];
    },
  },
  watch: {
    isAuthenticated() {
      this.fetchData();
    },
    '$route.meta.hideMainMenu': {
      handler(hideMainMenu) {
        this.hideMainMenu = !!hideMainMenu;
        // Close main menu when menu button is hidden
        if (hideMainMenu) {
          this.isMenuOpen = false;
        }
      },
      immediate: true,
    },
    '$route.meta.darkMode': {
      handler(darkMode) {
        this.darkMode = !!darkMode;
        // Enable or disable dark mode
        if (darkMode) {
          this.enableDarkMode();
        } else {
          this.disabledDarkmode();
        }
      },
      immediate: true,
    },
    '$route.meta.translucentHeader': {
      handler(translucentHeader) {
        this.translucentHeader = !!translucentHeader;
      },
      immediate: true,
    },
    'company.giphy': {
      handler(giphy) {
        if (!giphy) return;
        // Save url in Capacitor Storage
        set('giphy', giphy);
        // Preload giphy image
        const image = new Image();
        image.src = giphy;
      },
      immediate: true,
    },
  },
  created() {
    this.initPlatforms();
    this.initPushNotifications();
    this.initListeners();
  },
  methods: {
    initPlatforms() {
      // Set op platform specific settings
      switch (this.$capacitor.platform) {
        case 'ios':
          break;
        case 'android':
          // Force opaque status bar on Android because of this bug
          // https://github.com/ionic-team/ionic-framework/issues/17927
          StatusBarService.opaque();
          StatusBarService.dark();
          break;
        case 'web':
          break;
        default:
          break;
      }
    },
    initPushNotifications() {
      PushService.init();
    },
    initListeners() {
      // Listen for App opening / closing
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) this.fetchData();
      });
      // Listen for Universal and App Links
      Capacitor.addListener('App', 'appUrlOpen', ({ url }) => { // @TODO Temporary solution since App listener below is not working
      // App.addListener('appUrlOpen', ({ url }) => {
        openRouteFromUrl(url);
      });
    },
    fetchData() {
      if (!this.isAuthenticated) return;
      this.$store.dispatch('user/fetchUser');
      this.$store.dispatch('company/fetchCompany');
      this.$store.dispatch('activePulses/fetchActivePulses');
      this.$store.dispatch('inactivePulses/fetchInactivePulses');
    },
    enableDarkMode() {
      document.body.classList.add('dark');
      if (this.$capacitor.platform === 'ios') {
        StatusBarService.dark();
      }
    },
    disabledDarkmode() {
      document.body.classList.remove('dark');
      if (this.$capacitor.platform === 'ios') {
        StatusBarService.light();
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  align-items: stretch;
  height: -webkit-fill-available;
  overflow: auto;
  color: $color-text;
  background-color: $color-gray10;
  transition: background-color $page-transition-duration ease, color $page-transition-duration ease;
  -webkit-overflow-scrolling: touch;

  &--dark {
    color: $color-white;
    background-color: $color-primary90;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  margin-top: env(safe-area-inset-top);
  padding: $header-height 0;
}

.header {
  position: fixed;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
  width: 100%;
}

.body {
  flex-grow: 1;
  flex-shrink: 0;
}

</style>

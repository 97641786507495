<template>
  <form-field
    :label="label"
    :name="name"
    :focussed="focussed"
    :loading="loading"
    :disabled="disabled"
    :valid="valid"
    :invalid="invalid"
    :errors="errors"
  >
    <label
      class="select"
      :class="classes"
    >

      <select
        ref="select"
        class="select-tag"
        :name="name"
        :disabled="disabled || loading"
        @change="$emit('input', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      >
        <option
          v-if="placeholder !== null"
          value=""
        >
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option[optionValue]"
          :value="option[optionValue]"
          :selected="option[optionValue] === value"
        >
          {{ option[optionLabel] }}
        </option>
      </select>
      <transition-fade>
        <div
          v-if="statusIcon"
          class="status-icons"
        >
          <base-icon
            class="status-icon"
            :name="statusIcon"
            :animation="loading ? 'spin' : null"
          />
        </div>
      </transition-fade>
    </label>
  </form-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionValue: {
      type: String,
      default: 'id',
    },
    optionLabel: {
      type: String,
      default: 'value',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focussed: false,
    };
  },
  computed: {
    classes() {
      return {
        'select--slim': this.slim,
        'select--focussed': this.focussed,
        'select--loading': this.loading,
        'select--disabled': this.disabled,
        'select--valid': this.valid,
        'select--invalid': this.invalid,
      };
    },
    statusIcon() {
      if (this.loading) {
        return 'loader-outline';
      } if (this.disabled) {
        return 'lock-outline';
      } if (this.invalid) {
        return 'alert-triangle-outline';
      } if (this.valid) {
        return 'checkmark-outline';
      }
      return 'arrow-ios-downward-outline';
    },
  },
  mounted() {
    if (this.autofocus) this.focus();
  },
  methods: {
    onFocus() {
      this.$emit('focus');
      this.focussed = true;
    },
    onBlur() {
      this.$emit('blur');
      this.focussed = false;
    },
    focus() {
      this.$refs.select.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  font-family: $font-family-body;
  background: $color-white;
  border: solid 1px $color-gray70;
  border-radius: 4px;
  cursor: text;
  transition: border-color 150ms ease;

  &--valid {
    border-color: $color-success;

    .status-icons {
      color: $color-success;
    }
  }

  &--invalid {
    border-color: $color-danger;

    .status-icons {
      color: $color-danger;
    }
  }

  &--disabled,
  &--loading {
    background-color: $color-gray10;
    cursor: default;

    .select-tag,
    .status-icons {
      color: $color-gray70;
    }
  }

  &--focussed {
    border-color: $color-primary;
  }

  &--slim {
    height: 40px;
    background: transparent;
    border-top: none;
    border-right: none;
    border-bottom-width: 2px;
    border-left: none;
    border-radius: 0;

    .select-tag {
      padding: 0;
    }

    .status-icons {
      margin-right: 0;
    }
  }
}

.select-tag {
  flex-grow: 1;
  height: 100%;
  padding: 0 15px;
  font-size: 14px;
  font-family: $font-family-body;
  background: none;
  border: none;
  outline: none;
  appearance: none;
}

.status-icons {
  margin-right: 10px;
  transition: color 150ms ease;
}

</style>
